// react
import React from "react";

// polb theme
import Layout from "src/components/layout";
import SEO from "src/components/seo";

// google maps
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, GroundOverlay, InfoWindow } from "react-google-maps"

// map subcomponent
import PierDetails from "../../components/map/pier-details";
import MapArticle from "../../components/map/map-article";
import BerthDetails from "../../components/map/berth-details";

//images for PIERS & FACILITIES
import pierAll from 'src/images/port-info/map/piers/piers.svg';
import pierA from 'src/images/port-info/map/piers/hover-pier-a.svg';
import pierAWest from 'src/images/port-info/map/piers/hover-pier-a-west.svg';
import pierB from 'src/images/port-info/map/piers/hover-pier-b.svg';
import pierC from 'src/images/port-info/map/piers/hover-pier-c.svg';
import pierD from 'src/images/port-info/map/piers/hover-pier-d.svg';
import pierE from 'src/images/port-info/map/piers/hover-pier-e.svg';
import pierF from 'src/images/port-info/map/piers/hover-pier-f.svg';
import pierG from 'src/images/port-info/map/piers/hover-pier-g.svg';
import pierH from 'src/images/port-info/map/piers/hover-pier-h.svg';
import pierJ from 'src/images/port-info/map/piers/hover-pier-j.svg';
import pierS from 'src/images/port-info/map/piers/hover-pier-s.svg';
import pierT from 'src/images/port-info/map/piers/hover-pier-t.svg';

//icons
import iconDolphin from 'src/images/port-info/map/piers/markers/icon-dolphin.svg';
import iconGroup from 'src/images/port-info/map/piers/markers/icon-group.svg';
import iconIdea from 'src/images/port-info/map/piers/markers/icon-idea.svg';
import iconStarfish from 'src/images/port-info/map/piers/markers/icon-starfish.svg';
import pinIcon from 'src/images/port-info/map/piers/markers/pr_pin.png';

// labels for PIERS & FACILITIES
import markerA from "src/images/port-info/map/piers/markers/a.png";
import markerAWest from "src/images/port-info/map/piers/markers/a-west.png";
import markerB from "src/images/port-info/map/piers/markers/b.png";
import markerC from "src/images/port-info/map/piers/markers/c.png";
import markerD from "src/images/port-info/map/piers/markers/d.png";
import markerE from "src/images/port-info/map/piers/markers/e.png";
import markerF from "src/images/port-info/map/piers/markers/f.png";
import markerG from "src/images/port-info/map/piers/markers/g.png";
import markerH from "src/images/port-info/map/piers/markers/h.png";
import markerJ from "src/images/port-info/map/piers/markers/j.png";
import markerS from "src/images/port-info/map/piers/markers/s.png";
import markerT from "src/images/port-info/map/piers/markers/t.png";

// labels for badges
import marker47 from "src/images/port-info/map/piers/markers/badge_47.png";
import marker103 from "src/images/port-info/map/piers/markers/badge_103.png";
import marker710 from "src/images/port-info/map/piers/markers/badge_710.png";

// Markers for badgets
import markerBadgets from "src/images/port-info/map/fwy-legends.svg";

// cargo
//import cargoAll from "src/images/port-info/map/cargo/cargo.svg";
import cargoBreakBulk from "src/images/port-info/map/cargo/break-bulk.svg";
import cargoNeoBulk from "src/images/port-info/map/cargo/neo-bulk-cargo.svg";
import cargoBuildings from "src/images/port-info/map/cargo/buildings.svg";
import containerizedCargo from "src/images/port-info/map/cargo/containerized.svg";
import cargoPetroleum from "src/images/port-info/map/cargo/petroleum.svg";

// replacement graphic
import replacementGraphic from "src/images/port-info/map/replacement-graphic.svg"

// ES6 Imports
import { Events, scrollSpy, scroller } from 'react-scroll'
import { validateYupSchema } from "formik";
import { string } from "prop-types";

var classNames = require('classnames');
const fetch = require('isomorphic-fetch')

/* global google */

var defaultProps = {
  // How zoomed in you want the map to start at (always required)
  zoom: 14,

  // The latitude and longitude to center the map (always required)
  center: { lat: 33.7542, lng: -118.2165 }, // Port of long beach

  //streetViewControl: false,
  // scaleControl: false,
  //mapTypeControl: false,
  //panControl: false,
  //zoomControl: false,
  //rotateControl: false,
  //fullscreenControl: false,

  // How you would like to style the map.
  // This is where you would paste any style found on Snazzy Maps.
  styles: [
    {
      "featureType": "all",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#2A9CD0"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#767474"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#767474"
        }
      ]
    },
    {
      "featureType": "landscape.natural.landcover",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "saturation": "-1"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "color": "#2A9CD0"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#767474"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#8ABDE8"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#4D4D4D"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#898989"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#000B38"
        }
      ]
    }
  ]
};

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQ0dYekxiuF2E2Z8gyButaSL5ARp66GYY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    //defaultZoom={defaultProps.zoom, zoomC}
    //defaultOptions={{scrollwheel: false, zoom:false}}
    defaultCenter={{ lat: defaultProps.center.lat, lng: defaultProps.center.lng }}
    options={defaultProps}
  >
    {props.children}
  </GoogleMap>
);

//badges


const badges = [
  {
    id: "47",
    coords: { lat: 33.760404, lng: -118.238696 },
    markerIcon: marker47,
    info: null
  },
  {
    id: "103",
    coords: { lat: 33.775887, lng: -118.239214 },
    markerIcon: marker103,
    info: null
  },
  {
    id: "710",
    coords: { lat: 33.764546, lng: -118.214229 },
    markerIcon: marker710,
    info: null,
  },
  {
    id: "710-2",

    coords: { lat: 33.759500, lng: -118.228344 },
    markerIcon: marker710,
    info: null,
  },
];

//piers
const piersAndFacilitiesConfig = {
  pierAndFacilities: {
    id: "Piers and Facilities Overlay",
    overlay: pierAll,
    bounds: { south: 33.728331, west: -118.252768, north: 33.788306, east: -118.181257 },
    markers: [
      {
        id: "A",
        coords: { lat: 33.769692, lng: -118.235406 },
        overlay: pierA,

        markerIcon: markerA,
        info: null
      },
      {
        id: "A West",
        coords: { lat: 33.769520, lng: -118.244459 },
        overlay: pierAWest,
        markerIcon: markerAWest,
        info: null
      },
      {
        id: "B",
        coords: { lat: 33.775444, lng: -118.223184 },
        overlay: pierB,
        markerIcon: markerB,
        info: null,
      },
      {
        id: "C",
        coords: { lat: 33.768451, lng: -118.218364 },
        overlay: pierC,

        markerIcon: markerC,
        info: null
      },
      {
        id: "D",
        coords: { lat: 33.761629, lng: -118.217406 },
        overlay: pierD,

        markerIcon: markerD,
        info: null
      },
      {
        id: "E",
        coords: { lat: 33.761629, lng: -118.210904 },
        overlay: pierE,

        markerIcon: markerE,
        info: null
      },
      {
        id: "F",
        coords: { lat: 33.748343, lng: -118.209904 },
        overlay: pierF,

        markerIcon: markerF,
        info: null
      },
      {
        id: "G",
        coords: { lat: 33.749743, lng: -118.199482 },
        overlay: pierG,

        markerIcon: markerG,
        info: null
      },
      {
        id: "H",
        coords: { lat: 33.749010, lng: -118.190557 },
        overlay: pierH,

        markerIcon: markerH,
        info: null
      },
      {
        id: "J",
        coords: { lat: 33.732608, lng: -118.190127 },
        overlay: pierJ,

        markerIcon: markerJ,
        info: null
      },
      {
        id: "S",
        coords: { lat: 33.762002, lng: -118.232348 },
        overlay: pierS,

        markerIcon: markerS,
        info: null
      },
      {
        id: "T",
        coords: { lat: 33.753752, lng: -118.235357 },
        overlay: pierT,

        markerIcon: markerT,
        info: null
      },
    ]
  },
  cargoTypes: {
    id: "Cargo Types",
    overlay: pierAll,

    bounds: { south: 33.729230, west: -118.249468, north: 33.787205, east: -118.182057 },
    markers: [
      {
        id: 'Containerized',
        coords: { lat: 33.753752, lng: -118.235357 },
        overlay: containerizedCargo,

        markerIcon: markerT
      },

      {
        id: 'Dry Bulk',
        coords: { lat: 33.753752, lng: -118.235357 },
        overlay: cargoNeoBulk,

        markerIcon: markerT
      },
      {
        id: 'Liquid Bulk',
        coords: { lat: 33.753752, lng: -118.235357 },
        overlay: cargoPetroleum,

        markerIcon: markerT
      },
      {
        id: 'Break Bulk & RoRo',
        coords: { lat: 33.753752, lng: -118.235357 },
        overlay: cargoBreakBulk,

        markerIcon: markerT
      },
    ]
  },
  pointsOfInterest: null
};

const replacement = {
  id: "Replacement Overlay",
  overlay: replacementGraphic,
  bounds: { south: 33.728331, west: -118.252768, north: 33.788306, east: -118.181257 },
  markers: [
    {
      id: "replacement",
      coords: { lat: 33.761629, lng: -118.210904 },
      overlay: replacementGraphic,
      info: null
    },
  ]
}


export default class Map extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      optionSelect: 'option-1',
      testBounds: { south: 33.768831, west: -118.230668, north: 33.776806, east: -118.206257 },
      testUrl: pierAll,
      gran: 0.01,
      debugControls: false,
      selectedProject: null,
      selectedCargo: null,
      width: 0,
      height: 0,
      loadingMapConfig: true,
      pointsOfInterest: null,
      markerID: null,
      temporaryBerths: null,
      selectedBerth: null
    };

    this.selectOption = this.selectOption.bind(this);
    this.granUp = this.granUp.bind(this);
    this.granDown = this.granDown.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
    this.loadMapConfig = this.loadMapConfig.bind(this);


    this.displayCargoOverlayContainerized = this.displayCargoOverlay.bind(this, 'Containerized');
    this.displayCargoOverlayBuildings = this.displayCargoOverlay.bind(this, 'Dry Bulk');
    this.displayCargoOverlayPetroleum = this.displayCargoOverlay.bind(this, 'Liquid Bulk');
    this.displayCargoOverlayBreakBulk = this.displayCargoOverlay.bind(this, 'Break Bulk & RoRo');

    this.clearCargoOverlay = this.clearCargoOverlay.bind(this);

    this.selectionCallback = this.selectionCallback.bind(this);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.displayToolTip = this.displayToolTip.bind(this);
    // this.moveToolTip = this.moveToolTip.bind(this);
    this.removeToolTip = this.removeToolTip.bind(this);


  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.loadMapConfig();

    Events.scrollEvent.register('begin', function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadMapConfig() {
    //by default, wordpress only returns first 10 results
    fetch("https://thehelm.polb.com/wp-json/wp/v2/pier?per_page=100")
      .then(response => response.json())
      .then(obj => {
        //console.log(obj);
        //loop through the local hardcoded stuff
        piersAndFacilitiesConfig.pierAndFacilities.markers.forEach(current => {
          let findThisPier = current.id.toLowerCase().replace(/ /g, "-");

          let result = obj.find(element => {
            return element.slug.toLowerCase() === findThisPier;
          });

          if (result) {
            current.info = result.acf;
          } else {
            console.log('Could not find pier with slug "' + findThisPier + '"');
          }
        });

        this.setState({ loadingMapConfig: false });

      })
      .catch(e => {
        console.log(e);
      });

    fetch("https://thehelm.polb.com/wp-json/wp/v2/map-poi")
      .then(response => response.json())
      .then(pointsOfInterest => {
        this.setState({ pointsOfInterest });
      })
      .catch(e => {
        console.log(e);
      });

    fetch("https://thehelm.polb.com/wp-json/wp/v2/temporary-berths")
      .then(response => response.json())
      .then(temporaryBerths => {
        this.setState({ temporaryBerths });
      })
      .catch(e => {
        console.log(e);
      });
  }

  scrollToMoreInfo() {
    if (this.state.width < 912) {
      scroller.scrollTo('more-info-results-panel', {
        duration: 1000,
        delay: -100,
        smooth: true,
        offset: -50
      })
    }
  }

  scrollToMap() {
    if (this.state.width < 912) {
      scroller.scrollTo('map-container', {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -150
      })
    }
  }


  makeBounds(south, west, north, east) {
    return new google.maps.LatLngBounds(
      new google.maps.LatLng(south, west),
      new google.maps.LatLng(north, east));
  }

  selectOption(e) {
    e.preventDefault();
    let optionSelect = e.target.id;
    this.closeDetails(null, () => {
      this.setState({ optionSelect }, () => {

        //option 2 has sub-option so we don't scroll here
        if (this.state.optionSelect !== 'option-2') {
          this.scrollToMap();
        }
      });
    });
  }

  displayCargoOverlay(which) {
    let cargoObj = piersAndFacilitiesConfig.cargoTypes.markers.find((value) => {
      return value.id === which;
    });

    //get all the tenants by cargo type
    let tenants = [];
    piersAndFacilitiesConfig.pierAndFacilities.markers.forEach(current => {
      if (Array.isArray(current.info.tenants)) {
        current.info.tenants.forEach(tenant => {
          if (which === tenant.cargo_type) {
            tenants.push(tenant);
          }
        });
      } else {
        //console.log("No tenants for pier " + current.id);
      }
    });

    this.setState({ selectedCargo: cargoObj, selectedCargoTenants: tenants });
    this.scrollToMap();
  }

  displayProject(project) {
    this.setState({ selectedProject: project }, () => {
      this.scrollToMoreInfo();
    });
  }

  displayDetailsPanel(pier) {
    this.setState({ selectedPier: pier }, () => {
      this.scrollToMoreInfo();
    });
  }

  displayBerth(berth) {
    this.setState({ selectedBerth: berth }, () => {
      this.scrollToMoreInfo();
    });
  }

  move(dir) {
    let copy = Object.assign({}, this.state.testBounds);

    let gran = this.state.gran;

    if (dir === "up") {
      copy.north += gran;
      copy.south += gran;
    } else if (dir === "down") {
      copy.north -= gran;
      copy.south -= gran;
    } else if (dir === "left") {
      copy.west -= gran;
      copy.east -= gran;
    } else if (dir === "right") {
      copy.west += gran;
      copy.east += gran;
    } else if (dir === "expand-up") {
      copy.north += gran;
    } else if (dir === "expand-down") {
      copy.south -= gran;
    } else if (dir === "expand-left") {
      copy.west -= gran;
    } else if (dir === "expand-right") {
      copy.east += gran;
    } else if (dir === "collapse-up") {
      copy.north -= gran;
    } else if (dir === "collapse-down") {
      copy.south += gran;
    } else if (dir === "collapse-left") {
      copy.west += gran;
    } else if (dir === "collapse-right") {
      copy.east -= gran;
    }

    this.setState({ testBounds: copy }, () => {
      if (this.state.debugControls) {
        // console.log(this.state.testBounds);
      }
    });

  }

  granUp() {
    this.setState({ gran: this.state.gran * 10 });
  }

  granDown() {
    this.setState({ gran: this.state.gran / 10 });
  }

  closeDetails(event = null, callback = null) {

    if (event)
      event.preventDefault();

    return this.setState({ selectedPier: null, selectedProject: null, selectedCargo: null, selectedBerth: null }, () => {
      if (callback) callback();
      this.scrollToMap();
    });
  }

  getDebugControls() {
    return (
      <div>
        <button onClick={this.move.bind(this, "up")}>MOVE UP</button>
        <br />
        <button onClick={this.move.bind(this, "left")}>MOVE LEFT</button>
        <button onClick={this.move.bind(this, "down")}>MOVE DOWN</button>
        <button onClick={this.move.bind(this, "right")}>MOVE RIGHT</button>
        <hr />

        <hr />
        <br /><br /><br />

        <button onClick={this.move.bind(this, "expand-up")}>EXPAND UP</button>
        <br />
        <button onClick={this.move.bind(this, "expand-left")}>EXPAND LEFT</button>
        <button onClick={this.move.bind(this, "expand-down")}>EXPAND DOWN</button>
        <button onClick={this.move.bind(this, "expand-right")}>EXPAND RIGHT</button>
        <br />

        <hr />
        <br /><br /><br />

        <button onClick={this.move.bind(this, "collapse-up")}>COLLAPSE UP</button>
        <br />
        <button onClick={this.move.bind(this, "collapse-left")}>COLLAPSE LEFT</button>
        <button onClick={this.move.bind(this, "collapse-down")}>COLLAPSE DOWN</button>
        <button onClick={this.move.bind(this, "collapse-right")}>COLLAPSE RIGHT</button>
        <br />

        <br /><br /><br />
        <button onClick={this.granUp}>GRAN UP</button>
        <button onClick={this.granDown}>GRAN DOWN</button>
        <h3>{this.state.gran}</h3>
      </div>);
  }

  clearCargoOverlay() {
    this.setState({ selectedCargo: null });
  }

  selectionCallback() {
    this.scrollToMoreInfo();
  }

  displayToolTip(value) {
    // console.log(value);
    this.setState({ markerID: true });
  }



  removeToolTip() {
    this.setState({ showInfoWindow: false });
  }


  render() {
    let overlays = null;
    ///Overlay for the highways badgets

    if (!this.state.debugControls) {
      if (this.state.optionSelect === 'option-1') {
        let overlayObj = this.state.selectedPier ? this.state.selectedPier : piersAndFacilitiesConfig.pierAndFacilities;

        overlays = [
          <GroundOverlay
            key={'overlay-' + overlayObj.id} /* needed so if updates happen on some marker property, the map updates */
            defaultBounds={piersAndFacilitiesConfig.pierAndFacilities.bounds} //always draw using the original overlays bounds
            defaultUrl={overlayObj.overlay} />
        ];

        let markers = piersAndFacilitiesConfig.pierAndFacilities.markers.map((value) => {
          return <Marker key={'marker-' + value.id} onClick={this.displayDetailsPanel.bind(this, value)}
            defaultIcon={value.markerIcon}
            position={value.coords} />
        });

        overlays = overlays.concat(markers);

        let markersBadges = badges.map((value) => {
          return <Marker key={'marker-' + value.id}
            defaultIcon={value.markerIcon}
            position={value.coords} />
        });

        overlays = overlays.concat(markersBadges);

      } else if (this.state.optionSelect === 'option-2') {
        let overlayObj = this.state.selectedCargo ? this.state.selectedCargo : piersAndFacilitiesConfig.cargoTypes;

        overlays = [
          <GroundOverlay
            key={'cargo-' + overlayObj.overlay} /* needed so if updates happen on some marker property, the map updates */
            defaultBounds={piersAndFacilitiesConfig.pierAndFacilities.bounds} //always draw using the original overlays bounds
            defaultUrl={overlayObj.overlay} />
        ];

        let markers = piersAndFacilitiesConfig.pierAndFacilities.markers.map((value) => {
          return <Marker key={'marker-' + value.id} onClick={this.displayDetailsPanel.bind(this, value)}
            defaultIcon={value.markerIcon}
            position={value.coords} />
        });

        overlays = overlays.concat(markers);

        let markersBadgesCargos = badges.map((value) => {
          return <Marker key={'marker-' + value.id}
            defaultIcon={value.markerIcon}
            position={value.coords} />
        });

        overlays = overlays.concat(markersBadgesCargos);

      } else if (this.state.optionSelect === 'option-3' && this.state.temporaryBerths) {
        overlays = this.state.temporaryBerths.map(value => {
          return (
            <Marker key={'marker-' + value.id} onClick={this.displayBerth.bind(this, value.acf)}
              defaultIcon={pinIcon}
              position={{ lat: parseFloat(value.acf.coordinates.latitude), lng: parseFloat(value.acf.coordinates.longitude) }}
              onMouseOver={() => this.setState({ markerID: value.id })}
              onMouseOut={() => this.setState({ markerID: null })}
            >

              {
                this.state.markerID === value.id &&
                <InfoWindow>
                  <h4>{value.title.rendered.replace(/&#8217;/, "'")}</h4>
                </InfoWindow>
              }
            </Marker>
          )
        });

        let replacementOverlay = (
          <GroundOverlay
            key={'cargo-' + replacement.id}
            defaultBounds={replacement.bounds}
            defaultUrl={replacement.overlay}
          />
        )

        overlays = overlays.concat(replacementOverlay)
      } else if ((this.state.optionSelect === 'option-4' || this.state.optionSelect === 'option-5') && this.state.pointsOfInterest) {
        overlays = this.state.pointsOfInterest.map(value => {
          //points of interest with pulsating circles are PROJECTS (option 3)
          //points of interest with any other icon are HIGHLIGHTS (option 4)
          //here, we skip points of interest that don't fulfill the selection
          let iconSelection;
          if (value.acf.icon === 'iconPulsatingCircle') {
            if (this.state.optionSelect !== 'option-4')
              return null;
            else {
              iconSelection = <div className="icon-container">
                <img src={pinIcon} alt={"Point of Interest"} />
              </div>
            }
          } else {
            if (this.state.optionSelect !== 'option-5')
              return null;
            else {
              iconSelection = <div className="icon-container">
                <img src={pinIcon} alt={"Highlights"} />
              </div>
            }
          }

          return (
            <Marker key={'marker-' + value.id} onClick={this.displayProject.bind(this, value.acf)}
              defaultIcon={pinIcon}
              position={{ lat: parseFloat(value.acf.coordinates.latitude), lng: parseFloat(value.acf.coordinates.longitude) }}
              onMouseOver={() => this.setState({ markerID: value.id })}
              onMouseOut={() => this.setState({ markerID: null })}
            >

              {
                this.state.markerID === value.id &&
                <InfoWindow>
                  <h4>{value.title.rendered.replace(/&#8217;/, "'")}</h4>
                </InfoWindow>
              }
            </Marker>
          )
        });

        let replacementOverlay = (
          <GroundOverlay
            key={'cargo-' + replacement.id}
            defaultBounds={replacement.bounds}
            defaultUrl={replacement.overlay}
          />
        )

        overlays = overlays.concat(replacementOverlay)
      }
    }

    return (
      <Layout hasHero={true}>
        <SEO title="Interactive Port Map" description="" />
        <div className="interactive-map">

          {/*select the port feature*/}
          <div className="map-overlay controls" id="map-control-panel">
            <h3>Explore the map</h3>
            <hr />

            {this.state.debugControls && this.getDebugControls()}

            <ul>
              <li id='option-1' onClick={this.selectOption}
                className={classNames({ 'active': this.state.optionSelect === 'option-1' })}>Piers & Facilities
              </li>
              <li id='option-2' onClick={this.selectOption}
                className={classNames({ 'active': this.state.optionSelect === 'option-2' })}>Cargo Types
              </li>
              <li id='option-2-content' className={classNames({ 'shrink': this.state.optionSelect !== 'option-2' })}>
                <div className="suboption"
                  onClick={this.displayCargoOverlayContainerized}>
                  Containerized
                </div>
                <div className="suboption"
                  onClick={this.displayCargoOverlayBuildings}>
                  Dry Bulk
                </div>
                <div className="suboption"
                  onClick={this.displayCargoOverlayPetroleum}>
                  Liquid Bulk
                </div>
                <div className="suboption"
                  onClick={this.displayCargoOverlayBreakBulk}>
                  Break Bulk & RoRo
                </div>
              </li>
              <li id='option-3' onClick={this.selectOption}
                className={classNames({ 'active': this.state.optionSelect === 'option-3' })}>Temporary Berth Assignments
              </li>
              <li id='option-4' onClick={this.selectOption}
                className={classNames({ 'active': this.state.optionSelect === 'option-4' })}>Projects
              </li>
              <li id='option-5' onClick={this.selectOption}
                className={classNames({ 'active': this.state.optionSelect === 'option-5' })}>Highlights
              </li>
            </ul>
          </div>

          <div id="map-container" className={"map-container"}>
            <MyMapComponent isMarkerShown>

              {overlays}

              {/*for testing*/}
              {this.state.debugControls &&
                <GroundOverlay
                  key={Math.random()}
                  defaultBounds={this.state.testBounds}
                  defaultUrl={this.state.testUrl}
                  opacity={.8} />}
            </MyMapComponent>
          </div>

          {/*darkening*/}
          <div className="map-overlay gradient-1" />
          <div className="map-overlay gradient-2" />


          <div id={"more-info-results-panel"}>
            {/*more info*/}
            {this.state.selectedPier && !this.state.loadingMapConfig &&
              <PierDetails closePanelCallback={this.closeDetails} details={this.state.selectedPier} />
            }

            {/*more info controlled by cargo type*/}
            {this.state.selectedCargo && !this.state.loadingMapConfig &&
              <PierDetails closePanelCallback={this.closeDetails} title={this.state.selectedCargo.id} tenants={this.state.selectedCargoTenants} />
            }

            {/*news article panel*/}
            {this.state.selectedProject &&
              <MapArticle selectionCallback={this.selectionCallback} closePanelCallback={this.closeDetails} projectInfo={this.state.selectedProject} />
            }

            {/*temporary berths panel*/}
            {this.state.selectedBerth &&
              <BerthDetails closePanelCallback={this.closeDetails} berthInfo={this.state.selectedBerth} />
            }
          </div>
        </div>
      </Layout>
    );
  }
}
